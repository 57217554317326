<template>
  <v-card>
    <div class="px-5">
      <card-title :is-image="false" icon="mdi-timer-outline"
        ><span class="tw-flex tw-items-center tw-gap-1">
          TAT Reports
          <span v-if="process" class="c-blue-text tw-text-base"
            >({{ process.processDescription }})</span
          >
        </span>
      </card-title>
    </div>

    <v-card-text>
      <div
        class="
          tw-mb-4
          sm:tw-mb-1
          tw-flex tw-items-center tw-justify-between tw-flex-col
          sm:tw-flex-row
        "
      >
        <div class="tw-flex tw-items-center tw-gap-6">
          <div class="tw-flex tw-items-center tw-gap-1" v-if="fromDate">
            <p class="tw-text-black tw-font-semibold mb-0">From</p>
            <p class="c-gray-text mb-0">{{ fromDate }}</p>
          </div>

          <div class="tw-flex tw-items-center tw-gap-1" v-if="toDate">
            <p class="tw-text-black tw-font-semibold mb-0">To</p>
            <p class="c-gray-text mb-0">{{ toDate }}</p>
          </div>
        </div>

        <div
          class="
            tw-flex tw-items-center tw-gap-0
            sm:tw-gap-6
            tw-flex-col
            sm:tw-flex-row
          "
        >
          <v-select
            class="tw-w-full sm:tw-w-60 lg:tw-w-80"
            label="Process"
            v-model="process"
            :items="processes"
            item-text="processDescription"
            return-object
            @change="fetchReports"
          ></v-select>

          <v-btn
            color="primary"
            class="filter tw-w-full sm:tw-w-40"
            @click="openDateFilterDialog = true"
            >Filter By Date Range</v-btn
          >
        </div>
      </div>

      <div v-if="!isLoading && !tatReports.length" class="tw-my-3">
        <v-divider></v-divider>

        <div
          class="
            tw-flex
            tw-flex-col
            tw-items-center
            tw-justify-center
            tw-gap-3
            tw-py-16
          "
        >
          <v-icon x-large>mdi-text-box</v-icon>

          <div>
            <p class="c-gray-text mb-0 tw-text-center">
              Currently, there are no vehicles Turn Around Time(TAT)
            </p>
            <p class="c-gray-text mb-0 tw-text-center">
              reports for the work done. All reports will be
            </p>
            <p class="c-gray-text mb-0 tw-text-center">displayed here</p>
          </div>
        </div>
      </div>

      <div v-else>
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="tatReports"
          :loading="isLoading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 25, 50, 100],
          }"
          @update:options="loadItems"
        >
          <template v-slot:[`item.started`]="{ item }">
            <span>{{ item.process.started | formatToHumanWithTime }}</span>
          </template>

          <template v-slot:[`item.ended`]="{ item }">
            <span>{{ item.process.ended | formatToHumanWithTime }}</span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.process.status == 'OnTime'
                  ? 'success'
                  : item.process.status == 'InProgress'
                  ? 'secondary'
                  : item.process.status == 'Missing TAT Config' ||
                    item.process.status == 'N/A'
                  ? 'gray'
                  : 'error'
              "
              small
            >
              {{ item.process.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="primary"
              class="view-btn"
              text
              plain
              @click="viewTat(item)"
              >View</v-btn
            >
          </template>
        </v-data-table>
      </div>

      <date-filter
        v-if="openDateFilterDialog"
        :open="openDateFilterDialog"
        :isLoading="isLoading"
        :processDescription="process.processDescription"
        :fromDate="fromDate"
        :toDate="toDate"
        @close-dialog="openDateFilterDialog = false"
        @generate-reports="filterByDates"
      ></date-filter>

      <timeline
        v-if="timelineDialog.open"
        :open="timelineDialog.open"
        :id="timelineDialog.selectedId"
        @close-dialog="timelineDialog.open = false"
      ></timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import { formatToHumanWithTime } from '@/utils/time'
import axios from 'axios'
import DateFilter from './DateFilter'

export default {
  emits: ['set-table-data'],
  components: {
    CardTitle,
    DateFilter,
    timeline: () => import('./Timeline'),
  },
  data: () => ({
    isLoading: false,
    tatReports: [],
    headers: [
      {
        text: 'No.',
        align: 'start',
        sortable: false,
        value: 'bookingId',
      },
      {
        text: 'Reg No.',
        align: 'start',
        sortable: false,
        value: 'registrationNumber',
      },
      {
        text: 'Start Time',
        align: 'start',
        sortable: false,
        value: 'started',
      },
      {
        text: 'End Time',
        align: 'start',
        sortable: false,
        value: 'ended',
      },
      {
        text: 'Duration',
        align: 'start',
        sortable: false,
        value: 'process.timeTaken',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'action',
      },
    ],
    processes: [],
    process: null,
    fromDate: '',
    toDate: '',
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    openDateFilterDialog: false,
    timelineDialog: {
      open: false,
      selectedId: '',
    },
  }),
  methods: {
    async fetchProcesses() {
      await axios
        .get('/motor-assessment/api/tat-configs/processes')
        .then(async ({ data }) => {
          if (data.success) {
            this.processes = data.data
            this.process = await this.processes.find(
              (process) => process.processId == 'BOOKING'
            )

            this.process && this.fetchReports()
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to fetch TAT processes',
            variant: 'error',
          })
        })
    },
    async fetchReports() {
      this.isLoading = true

      await axios
        .get(`/motor-assessment/api/reports/tat/${this.process.processId}`, {
          params: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            page: this.pagination.page,
            size: this.pagination.itemsPerPage,
          },
        })
        .then(({ data }) => {
          if (data.success) {
            this.tatReports = data.data.filter(
              (item) => item.process.status !== 'N/A'
            )

            this.$emit('set-table-data', {
              process: this.process.processId,
              hasData: !!this.tatReports.length,
              fromDate: this.fromDate,
              toDate: this.toDate,
              page: this.pagination.page,
              size: this.pagination.itemsPerPage,
            })
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: data.msg ?? 'Failed to fetch TAT reports',
              variant: 'error',
            })
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to fetch TAT reports',
            variant: 'error',
          })
        })
        .finally(() => (this.isLoading = false))
    },
    loadItems(val) {
      if (
        val.page != this.pagination.page ||
        val.itemsPerPage != this.pagination.itemsPerPage
      ) {
        this.pagination = {
          page: val.page,
          itemsPerPage: val.itemsPerPage,
        }

        this.fetchReports()
      }
    },
    async filterByDates(fromDate, toDate) {
      this.fromDate = fromDate
      this.toDate = toDate

      await this.fetchReports()

      this.openDateFilterDialog = false
    },
    viewTat(item) {
      this.timelineDialog = {
        open: true,
        selectedId: item.id,
      }
    },
  },
  beforeMount() {
    this.fetchProcesses()
  },
  filters: {
    formatToHumanWithTime,
  },
}
</script>

<style>
.view-btn {
  padding: 0px !important;
  min-width: fit-content !important;
}

button.filter {
  border-radius: 40px !important;
  font-size: 12px !important;
}
</style>
