<template>
  <v-dialog v-model="open" width="400" persistent>
    <v-card>
      <v-card-title>
        <h3 class="tw-w-full tw-text-center">Date Filter</h3>

        <p class="c-blue-text tw-w-full tw-text-center mb-0">
          ({{ processDescription }})
        </p>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu v-model="menu1" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="from"
                      clearable
                      placeholder="Date From"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="from = ''"
                      class="p-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="tw-w-full"
                    color="primary"
                    v-model="from"
                    @change="menu1 = false"
                    :max="new Date().toISOString().substring(0, 10)"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-menu v-model="menu2" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="to"
                      clearable
                      placeholder="Date To"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="to = ''"
                      class="p-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="tw-w-full"
                    color="primary"
                    v-model="to"
                    @change="menu2 = false"
                    :max="new Date().toISOString().substring(0, 10)"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  class="tw-w-full"
                  color="secondary"
                  outlined
                  @click="closeDialog"
                  >Cancel</v-btn
                >
              </v-col>

              <v-col cols="12" sm="6">
                <v-btn
                  class="tw-w-full"
                  color="secondary"
                  :loading="isLoading"
                  @click="filter"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  emits: ['close-dialog', 'generate-reports'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    processDescription: {
      type: String,
      required: true,
    },
    fromDate: {
      type: String,
      required: true,
    },
    toDate: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    from: '',
    to: '',
  }),
  created() {
    this.from = this.fromDate
    this.to = this.toDate
  },
  methods: {
    filter() {
      if (moment(this.from).isAfter(this.to)) {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: 'Invalid date range',
          variant: 'error',
        })

        return
      }

      this.$emit('generate-reports', this.from, this.to)
    },
    closeDialog() {
      if (!this.fromDate && !this.toDate) {
        this.from = ''
        this.to = ''
      }

      this.$emit('close-dialog')
    },
  },
}
</script>
